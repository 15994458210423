// 用於使 table 具備 hover color 與 toggle expand area 功能
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['expandable', 'highlight']

  // hover 的 highlight
  highlightClassAttributeName = 'data-table-highlight-class'
  highlightColorClass         = 'bg-gray-200'

  // 選取的 highlight
  selectedHighlightClassAttributeName = 'data-table-selected-highlight-class'
  selectedHighlightColorClass         = 'bg-gray-400'

  // highlight 時被刪除，unhighlight 時被加回來的 class
  replacedClassAttributeName  = 'data-table-replaced-class'

  connect() {
    // 因為 table 寬度有可能比可顯示區域寬，因此將 expandable area 的寬度動態設為與外層 table wrapper 相等
    // 以免 expandable area 的內容較多時，內容被擠到 table 隱藏的區域，
    // 變得需要捲動才能完整看到所有的 expandable area 內容
    var targetWidth = document.getElementById('table-wrapper').offsetWidth
    this.element.getElementsByClassName('expandable-content-wrapper')[0].style.width = targetWidth + 'px'
  }

  toggleExpand() {
    // Toggle show/hide target element
    this.expandableTarget.classList.toggle('hidden')

    // Toggle highlight target element
    if (this.expandableTarget.classList.contains('hidden')) {
      // 收起時移除 highlight
      this._removeSelectedHighlight()
    }
    else {
      // 展開時加上 highlight
      this._addSelectedHighlight()
    }
  }

  highlight() {
    var highlightClass = this.data.get('highlightClass') || this.highlightColorClass

    this.highlightTargets.forEach((el, i) => {
      var _highlightClass = el.hasAttribute(this.highlightClassAttributeName) ? el.getAttribute(this.highlightClassAttributeName) : highlightClass
      // Insert at beginning of classList
      el.className = _highlightClass + ' ' + el.className

      // 若有定義 highlight 時要移除的 class 則移除
      if (el.hasAttribute(this.replacedClassAttributeName)) {
        el.classList.remove(el.getAttribute(this.replacedClassAttributeName).split(' '))
      }
    })
  }

  unhighlight() {
    var highlightClass = this.data.get('highlightClass') || this.highlightColorClass

    this.highlightTargets.forEach((el, i) => {
      var _highlightClass = el.hasAttribute(this.highlightClassAttributeName) ? el.getAttribute(this.highlightClassAttributeName) : highlightClass
      el.classList.remove(_highlightClass)

      // 若有定義 highlight 時要移除的 class 則移除
      if (el.hasAttribute(this.replacedClassAttributeName)) {
        el.classList.add(el.getAttribute(this.replacedClassAttributeName).split(' '))
      }
    })
  }

  _addSelectedHighlight() {
    var selectedHighlightClass = this.data.get('selectedHighlightClass') || this.selectedHighlightColorClass

    this.highlightTargets.forEach((el, i) => {
      var _selectedHighlightClass = el.hasAttribute(this.selectedHighlightClassAttributeName) ? el.getAttribute(this.selectedHighlightClassAttributeName) : selectedHighlightClass
      // Insert at beginning of classList
      el.className = _selectedHighlightClass + ' ' + el.className

      // 若有定義 highlight 時要移除的 class 則移除
      if (el.hasAttribute(this.replacedClassAttributeName)) {
        el.classList.remove(el.getAttribute(this.replacedClassAttributeName).split(' '))
      }
    });
  }

  _removeSelectedHighlight() {
    var selectedHighlightClass = this.data.get('selectedHighlightClass') || this.selectedHighlightColorClass

    this.highlightTargets.forEach((el, i) => {
      var _selectedHighlightClass = el.hasAttribute(this.selectedHighlightClassAttributeName) ? el.getAttribute(this.selectedHighlightClassAttributeName) : selectedHighlightClass
      el.classList.remove(_selectedHighlightClass)

      // 若有定義 highlight 時要移除的 class 則移除 highlight 加回來
      if (el.hasAttribute(this.replacedClassAttributeName)) {
        el.classList.add(el.getAttribute(this.replacedClassAttributeName).split(' '))
      }
    });
  }
}
