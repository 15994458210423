// Show/hide toggle
// 若有 highlight target，需指定 highlight 的 class 於 data-toggle-highlight-class
// 可於 data-controller 的 element 設定全域的 highlight class
// 或是被 highlight 的 element 設定該 element 的 highlight class
//
// 若 highlight 時有 class 需要移除，則設定於 data-toggle-replace-while-highlighted-class

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['togglable', 'highlight']

  highlightClassAttributeName = 'data-toggle-highlight-class'
  replaceWhileHighlightedClassName = 'data-toggle-replace-while-highlighted-class'

  toggle() {
    // Toggle show/hide target element
    this.togglableTarget.classList.toggle('hidden')

    // Toggle highlight target element
    if (this.togglableTarget.classList.contains('hidden')) {
      // 收起時移除 highlight
      this._removeHighlight()
    }
    else {
      // 展開時加上 highlight
      this._addHighlight()
    }
  }

  _addHighlight() {
    var highlightClass = this.data.get('highlightClass')

    this.highlightTargets.forEach((el, i) => {
      var _highlightClass = el.hasAttribute(this.highlightClassAttributeName) ? el.getAttribute(this.highlightClassAttributeName) : highlightClass
      // el.classList.add(this.highlightColorClass)
      // Insert at beginning of classList
      el.className = _highlightClass + ' ' + el.className

      // 若有定義 highlight 時要移除的 class 則移除
      if (el.hasAttribute(this.replaceWhileHighlightedClassName)) {
        el.classList.remove(el.getAttribute(this.replaceWhileHighlightedClassName))
      }
    });
  }

  _removeHighlight() {
    var highlightClass = this.data.get('highlightClass')

    this.highlightTargets.forEach((el, i) => {
      var _highlightClass = el.hasAttribute(this.highlightClassAttributeName) ? el.getAttribute(this.highlightClassAttributeName) : highlightClass
      el.classList.remove(_highlightClass)

      // 若有定義 highlight 時要移除的 class 則移除 highlight 加回來
      if (el.hasAttribute(this.replaceWhileHighlightedClassName)) {
        el.classList.add(el.getAttribute(this.replaceWhileHighlightedClassName))
      }
    });
  }
}
