import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['profile', 'row', 'wholeInfo']

  hoverColorClass = 'bg-gray-200'
  selectedColorClass = 'bg-primary-100'

  toggleProfile() {
    this.profileTarget.classList.toggle('hidden')

    // 收起時移除 highlight
    if (this.profileTarget.classList.contains('hidden')) {
      this.rowTarget.classList.remove(this.selectedColorClass)
    }
    // 展開時加上 highlight
    else {
      this.rowTarget.classList.add(this.selectedColorClass)
    }
  }

  highlight() {
    this.wholeInfoTarget.classList.add(this.hoverColorClass)
  }

  unhighlight() {
    this.wholeInfoTarget.classList.remove(this.hoverColorClass)
  }
}
