import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['modal', 'dismissButton']

  show() {
    this.modalTarget.classList.remove('hidden')
    this.dismissButtonTarget.classList.remove('hidden')
  }

  dismiss() {
    this.modalTarget.classList.add('hidden')
    this.dismissButtonTarget.classList.add('hidden')
  }
}
