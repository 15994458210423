import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["rows", "charts"]

  hoverColorClass    = 'bg-gray-200'
  selectedColorClass = 'bg-primary-100'

  // Highlight row by setting background color
  highlight(ev) {
    let currentRowNumber = ev.currentTarget.dataset.financialsRowNumber
    // 當前 hover 的 row 是否已經被 use 點選
    let isCurrentRowSelected = this.userSelectedRowNumbers().indexOf(parseInt(currentRowNumber)) > -1 ? true : false
    this.rowsTargets.forEach((el, i) => {
      if (el.dataset.financialsRowNumber === currentRowNumber && !isCurrentRowSelected) {
        el.classList.add(this.hoverColorClass)
      }
    })
  }

  // Unhighlight row by removing background color
  unhighlight(ev) {
    let currentRowNumber = ev.currentTarget.dataset.financialsRowNumber
    this.rowsTargets.forEach((el, i) => {
      if (el.dataset.financialsRowNumber === currentRowNumber) {
        el.classList.remove(this.hoverColorClass)
      }
    })
  }

  toggleChart(ev) {
    // User 當前點擊的 row 之 number
    let currentRowNumber = parseInt(ev.currentTarget.dataset.financialsRowNumber)
    // 被 user 選取的 row
    let selectedRowNumbers = this.userSelectedRowNumbers()

    let arrayIndex = selectedRowNumbers.indexOf(currentRowNumber)

    if (arrayIndex > -1) {
      selectedRowNumbers.splice(arrayIndex, 1)
    }
    else {
      selectedRowNumbers.push(currentRowNumber)
    }

    this.saveSeletedRowNumbers(selectedRowNumbers)
    // 顯示所選取的圖表
    this.showChartsByNumbers(selectedRowNumbers)
    // Highlight 所選取的 row
    this.highlightRowsByNumbers(selectedRowNumbers)
  }

  hideChart(ev) {
    let targetRowNumber = parseInt(ev.currentTarget.parentNode.parentNode.dataset.financialsChartNumber)
    let selectedRowNumbers = this.userSelectedRowNumbers()
    let arrayIndex = selectedRowNumbers.indexOf(targetRowNumber)

    selectedRowNumbers.splice(arrayIndex, 1)

    this.saveSeletedRowNumbers(selectedRowNumbers)
    this.showChartsByNumbers(selectedRowNumbers)
    this.highlightRowsByNumbers(selectedRowNumbers)
  }

  // 被 user 選取的 row
  userSelectedRowNumbers() {
    if (this.data.get('selected-row-numbers') === '') {
      return []
    }
    else {
      // Don't change map((v) => parseInt(v)) to map(parseInt) or you'll be FUCKED by JS
      // See https://medium.com/dailyjs/parseint-mystery-7c4368ef7b21
      return this.data.get('selected-row-numbers').split(",").map((v) => parseInt(v))
    }
  }

  saveSeletedRowNumbers(rowNumbers) {
    this.data.set('selected-row-numbers', rowNumbers.join(","))
  }

  showChartsByNumbers(chartNumbersToShow) {
    this.chartsTargets.forEach((el, i) => {
      if (chartNumbersToShow.indexOf(parseInt(el.dataset.financialsChartNumber)) > -1) {
        el.classList.remove('hidden')
      }
      else {
        el.classList.add('hidden')
      }
    })
  }

  highlightRowsByNumbers(rowNumbersToHighlight) {
    this.rowsTargets.forEach((el, i) => {
      if (rowNumbersToHighlight.indexOf(parseInt(el.dataset.financialsRowNumber)) > -1) {
        el.classList.add(this.selectedColorClass)
        el.classList.remove(this.hoverColorClass)
      }
      else {
        el.classList.remove(this.selectedColorClass)
      }
    })
  }
}
