import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['container']

  initialize() {
    this.showOnConnected = this.element.getAttribute("data-cb-chart-show-on-connected") === 'true' ? true : false
  }

  connect() {
    if (this.showOnConnected) {
      this.loadChart()
    }
  }

  loadChart() {
    if (this.chartLoaded === true) {
      return
    }
    fetch('/api/prices/' + this.data.get('stock-id'))
      .then(resp => {
        return resp.json()
      }).then(json => {
        this._createChart(json.data)
      })
  }

  _createChart(chartData) {
    // am4core.useTheme(am4themes_animated);

    let chart = am4core.create(this.containerTarget, am4charts.XYChart);

    chart.dateFormatter.inputDateFormat = "yyyy-MM-dd";

    let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    dateAxis.renderer.grid.template.location = 0;

    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.tooltip.disabled = true;

    let series = chart.series.push(new am4charts.CandlestickSeries());
    series.dataFields.dateX = "date";
    series.dataFields.valueY = "close";
    series.dataFields.openValueY = "open";
    series.dataFields.lowValueY = "low";
    series.dataFields.highValueY = "high";
    series.simplifiedProcessing = true;
    // 漲:紅色 跌:綠色
    series.dropFromOpenState.properties.fill = am4core.color('#48bb78')
    series.dropFromOpenState.properties.stroke = am4core.color('#48bb78')
    series.riseFromOpenState.properties.fill = am4core.color('#f86a6a')
    series.riseFromOpenState.properties.stroke = am4core.color('#f86a6a')

    series.tooltipText = "開: {openValueY.value}\n高: {highValueY.value}\n低: {lowValueY.value}\n收: {valueY.value}";

    chart.cursor = new am4charts.XYCursor();

    chart.data = chartData

    this.chartLoaded = true // Load chart only once.
  }
}
