import { Controller } from "stimulus"

export default class extends Controller {
    static targets = ['result', 'input', 'dismissButton']

    query(event) {
        var q = event.target.value.trim()
        if (!q || q.length === 0) {
            this.resultTarget.innerHTML = ''
            this._hide(this.resultTarget)
            this._hide(this.dismissButtonTarget)
            return
        }

        fetch(this.data.get('url') + '/' + q)
            .then(res => res.text())
            .then(html => {
                this.resultTarget.innerHTML = html
                this._show(this.resultTarget)
                this._show(this.dismissButtonTarget)
            })
    }

    hideResult(event) {
        // 避免點擊搜尋結果的那一刻前因為 focusout 了搜尋 bar 導致搜尋結果被隱藏而無法點擊搜尋結果，
        // 因此需要先判斷是否點擊了 descendants (搜尋結果為 descendant)，如果不是點擊了 descendant 再隱藏搜尋結果
        if (!event.currentTarget.contains(event.relatedTarget)) {
            this._hide(this.resultTarget)
            this._hide(this.dismissButtonTarget)
        }
    }

    showResult(event) {
        var q = event.target.value
        if (q && q.length > 0) {
            this._show(this.resultTarget)
            this._show(this.dismissButtonTarget)
        }
    }

    _hide(element) {
        element.classList.add('hidden')
    }

    _show(element) {
        element.classList.remove('hidden')
    }
}